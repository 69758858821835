<template>
  <div>
    <page-reports-actions-button
      v-for="action of displayedActions"
      :key="action.key"
      :label="action.label"
      :disabled="action.disabled"
      class="page-reports-actions__button"
      @click="action.handler"
    />
    <confirm-button
      :target="target"
      type="default"
      @confirm="deleteAction.handler"
      :disabled="deleteAction.disabled || this.$hasNoPermission('ffsecurity.delete_report')"
      class="page-reports-actions__button"
      v-if="deleteAction"
    >
    </confirm-button>
  </div>
</template>

<script>
import PageReportsActionsButton from './page-reports-actions-button';
import ConfirmButton from '../confirm/confirm-button';

export default {
  name: 'PageReportsActions',
  props: {
    actions: {
      type: Array,
      required: true
    },
    plural: {
      type: Boolean,
      required: true
    }
  },
  components: {
    PageReportsActionsButton,
    ConfirmButton
  },
  computed: {
    displayedActions() {
      return this.actions.filter((action) => action.key !== 'delete');
    },
    deleteAction() {
      return this.actions.find((action) => action.key === 'delete');
    },
    target() {
      return this.plural ? 'reports' : 'report';
    }
  }
};
</script>
