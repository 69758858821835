<template>
  <el-button size="small" v-text="label" :disabled="disabled" @click.stop="dispatchClickEvent" />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PageReportsActionsButton',
  props: {
    label: { type: String, required: true },
    disabled: { type: Boolean, default: false }
  }
})
export default class extends Vue {
  dispatchClickEvent() {
    this.$emit('click');
  }
}
</script>
